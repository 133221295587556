import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from "react-redux";
import DefaultLayout from "../../Layout/PortalTemplate";
import {
    BodyContainer,
    Report,
    BlockReport,
    WrapperTitle,
    WrapperContent,
    Title,
    WrapperItemConent,
    WrapperButton,
    UploadContainer,
    UploadListItem,
    UploadTemplate
} from "./UserApplyApplications.styles";
import Constants from "../../../constants/Constants";
import FundingApplicationUserActions from "../../../actions/FundingApplicationsUser";
import GuestDocumentTemplateSettingActions from "../../../actions/GuestDocumentTemplateSetting";
import Button from "../Button";
import Utils from "../../../libs/Utils";
import history from "../../../history";
import ProgressBar from "../ProgressBar";
import { ColorName } from "../../Variables";
import { LabelSlide } from "../CommonLayout";

const UserApplyApplications = () => {
    const { t } = useTranslation();
    const userData = Utils.getSavedUserData();
    const isLoading = useSelector((state) => state.FundingApplicationsUser.isLoading);
    const dispatch = useDispatch();
    const FundingStorage = useSelector((state) => state.FundingApplicationsUser);
    const [dataFundingUserReport, setDataFundingUserReport] = useState();
    const [applyApplication, setApplyApplication] = useState("");
    const [applyApplicationDetails, setApplyApplicationDetails] = useState([]);
    const [isSave, setIsSave] = useState(false); 
    const [titleApply, setTitleApply] = useState("");  // eslint-disable-line
    const [listFile, setListFile] = useState([]);
    const [listFileRemove, setListFileRemove] = useState([]);
    const [uploadFileDocument, setUploadFileDocument] = useState({ // eslint-disable-line
        name: '',
        url: ''
    });
    const cookies = new Cookies();
    useEffect(() => {
        cookies.remove(`${userData.id}${applyApplication.id}`);
        dispatch(FundingApplicationUserActions.getFundingUserReportRequest());
        dispatch(GuestDocumentTemplateSettingActions.getDocumentTemplateSettingData());
        if (_.isEmpty(FundingStorage.applyApplication) && !isLoading) {
            if (userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) {
                history.push("user-funding");
            } else {
                history.push("user-overview");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isSave) return;
        setDataFundingUserReport(FundingStorage.dataFundingUserReport);
        setApplyApplication(FundingStorage.applyApplication);
        const files = [];
        if (_.isEmpty(files)) files.push({});
        setListFile(files);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FundingStorage]);

    useEffect(() => {
        const dataSave = cookies.get(`${userData.id}${applyApplication.id}`);
        if (!_.isUndefined(dataSave)) {
            setApplyApplicationDetails(dataSave);
        } else if (applyApplication) {
            const newApplyApplicationDetails = [];
            _.map(!_.isEmpty(applyApplication) && applyApplication.questionnaire.questionCategories, (category) => {
                _.map(category.questionDetails, (field) => {
                    return newApplyApplicationDetails.push({
                        question_detail_id: field.id,
                        answer: field.answer || '',
                        is_mandatory: field.is_mandatory
                    });
                });
            });
            setApplyApplicationDetails(newApplyApplicationDetails);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyApplication]);

    const onChangeField = (value, key, field) => {
        const newApplyApplicationDetails = [...applyApplicationDetails];
        if (_.isEmpty(newApplyApplicationDetails)) {
            newApplyApplicationDetails.push({
                question_detail_id: key,
                answer: value || '',
                is_mandatory: field.is_mandatory
            });
        } else {
            let flag = false;
            _.map(newApplyApplicationDetails, (application) => {
                if (application.question_detail_id === key) {
                    flag = true;
                    application.answer = value;
                }
            });
            if (!flag) {
                newApplyApplicationDetails.push({
                    question_detail_id: key,
                    answer: value || '',
                    is_mandatory: field.is_mandatory
                });
            }
        }

        setApplyApplicationDetails(newApplyApplicationDetails);
    };

    const _renderTemplates = () => {
        const renderDocument = _.map(applyApplication.document_json, (child, index) => {
            return (
                <div className="upload-item" key={index}>
                    <a href={child} target="_blank" rel="noopener noreferrer">
                        {_.last(index.split("/"))}
                    </a>
                </div>
            );
        });
        return renderDocument;
    };
    const displayValue = (field) => {
        let value = "";
        const newApplyApplicationDetails = [...applyApplicationDetails];
        _.map(newApplyApplicationDetails, (child) => {
            if (child.question_detail_id === field.id) return value = child.answer;
        });
        return value;
    };

    const _renderField = (field, key) => {
        if (!_.isEmpty(field)) {
            if (field.question_type === 1) {
                return (
                    <WrapperTitle key={key} marginTop="1em">
                        <div className="fonts-status">
                            {field.title}
                            {' '}
                            {field.is_mandatory ? "*" : ""}
                        </div>
                        <textarea
                            defaultValue={displayValue(field)}
                            maxLength={field.max_characters && field.max_characters !== 0 ? field.max_characters : undefined}
                            onChange={(event) => onChangeField(event.target.value, field.id, field)}
                        />
                    </WrapperTitle>
                );
            }
        }
    };

    const _renderCategories = () => {
        if (!_.isEmpty(applyApplication)) {
            const render_new = _.map(!_.isEmpty(applyApplication) && applyApplication.questionnaire.questionCategories, (category, index) => {
                return (
                    <div key={index}>
                        <WrapperTitle marginTop="2em" marginLeft="0">
                            <div className="fonts-category">{`${index + 1}. ${category.title}`}</div>
                        </WrapperTitle>
                        <WrapperItemConent>
                            {
                                _.map(category.questionDetails, (field) => {
                                    return _renderField(field, field.id);
                                })
                            }
                        </WrapperItemConent>
                    </div>
                );
            });
            return render_new;
        }
    };

    const submitQuestionnaire = () => {
        // const data = {
        //     title: titleApply,
        //     funding_application_id: applyApplication.id,
        //     apply_application_details: JSON.stringify([...applyApplicationDetails]),
        //     file: uploadFileDocument.url
        // };
        if (_.isEmpty(applyApplicationDetails) && applyApplicationDetails.length !== 0) {
            return Utils.popupAlert({ title: "Oops...", text: t("alert.Fields cannot be blank"), type: "error" });
        } else {
            let error = 0;
            let isCheckNull = 0;
            _.map(applyApplicationDetails, (value) => {
                if (value.is_mandatory === true) {
                    if (value.answer === "") {
                        error += 1;
                    } else if (value.answer !== '' && _.isNil(value.answer)) {
                        isCheckNull += 1;
                    }
                }
            });
            if (isCheckNull !== 0) return Utils.popupAlert({ title: "Oops...", text: t("alert.Fields cannot be blank"), type: "error" });
            const formData = new FormData();
            formData.append("title", titleApply);
            formData.append("funding_application_id", applyApplication.id);
            formData.append("apply_application_details", JSON.stringify([...applyApplicationDetails]));
            if (_.isArray(listFile)) {
                _.map(listFile, (child, index) => {
                    if (child.name) formData.append(`document[${index}]`, child);
                });
            }
            if (error === 0) setIsSave(true);
            error === 0
                ? dispatch(FundingApplicationUserActions.submitApplyApplicationRequest(formData))
                : Utils.popupAlert({ title: "Oops...", text: t("alert.Fields cannot be blank"), type: "error" });
        }
    };

    const scrollVertical = async (title) => {
        if (userData.role.name !== Constants.ROLE_NAME.LIMITED_USER) {
            await history.push("user-funding");
            window.location.href = `${title}`;
        } else {
            history.push("user-overview");
        }
    };

    const handChangeFileForWinner = (event, index, nameFile) => {
        if (event.target.files.length !== 0) {
            if (!listFileRemove.includes(nameFile)) listFileRemove.push(nameFile);
            const temp = [...listFile];
            temp[index] = event.target.files[0];
            setListFile(temp);
        }
    };

    const addNewFile = () => {
        const temp = [...listFile];
        temp.push({});
        setListFile(temp);
    };

    const removeNewFile = (index, file) => {
        const arrayRemove = [];
        if (!_.isEmpty(file)) {
            if (!_.has(file, "name")) {
                arrayRemove.push(_.keys(file).toString());
            }
        }
        const files = [...listFile];
        if (index > -1) {
            files.splice(index, 1);
        }
        if (_.isEmpty(files)) files.push({});
        setListFile(files);
        setListFileRemove(arrayRemove);
    };

    const _renderUploadTemplate = () => {
        const renderNew = _.map(listFile, (e, i) => {
            const nameFile = !_.isUndefined(listFile[i].name) ? listFile[i].name : _.keys(listFile[i]).toString();
            return (
                <div key={i} className="input-file-container">
                    <LabelSlide className="upload-file">
                        <input type="file" onChange={(event) => handChangeFileForWinner(event, i, nameFile)} />
                        {listFile[i] && (
                            <p>
                                <i className="fas fa-upload" />
                                {!_.isUndefined(listFile[i].name) ? Utils.getNameFileUpload(listFile[i].name) : Utils.getNameFileUpload(_.keys(listFile[i]).toString()) || t("general.Choose file")}
                            </p>
                        )}
                    </LabelSlide>
                    {(i === 0 && listFile[i])
                        && (
                            <div className="action-upload">
                                <span onClick={() => addNewFile()}><i className="fas fa-plus" /></span>
                                <span onClick={() => removeNewFile(i, listFile[i])}><i className="fas fa-trash-alt" /></span>
                            </div>
                        )
                    }
                    {i !== 0
                        && (
                            <div className="action-upload">
                                <span onClick={() => removeNewFile(i, listFile[i])}><i className="fas fa-trash-alt" /></span>
                            </div>
                        )
                    }
                </div>
            );
        });
        return renderNew;
    };

    const saveDraft = () => {
        cookies.set(`${userData.id}${applyApplication.id}`, applyApplicationDetails, { path: '/', expires: new Date(Date.now() + 2592000) });
        Utils.popupAlert({ title: t("alert.Success"), text: t("general.Draft was saved"), type: "success" });
    };

    return (
        <DefaultLayout
            userRole='user'
            isLoading={isLoading}
            page="userFunding"
            content={(
                <BodyContainer>
                    <Report>
                        <BlockReport>
                            <ProgressBar
                                value={!_.isEmpty(dataFundingUserReport) ? dataFundingUserReport.my_application_amount === 0 ? 0 : dataFundingUserReport.my_application_amount : 0}
                                title={t("general.My Funding Applications")}
                                callBackAction={() => scrollVertical("#my-applications")}
                                filter="user overview"
                                total={100}
                            />
                        </BlockReport>
                        {userData.role.name !== Constants.ROLE_NAME.LIMITED_USER && (
                            <BlockReport>
                                <ProgressBar
                                    value={!_.isEmpty(dataFundingUserReport) ? dataFundingUserReport.funding_application_amount === 0 ? 0 : dataFundingUserReport.funding_application_amount : 0}
                                    title={t("fundingApplications.Available Funding Opportunities")}
                                    callBackAction={() => scrollVertical("#apply-applications")}
                                    filter="user overview"
                                    total={100}
                                />
                            </BlockReport>
                        )}
                        <BlockReport />
                    </Report>
                    <WrapperTitle marginLeft="0">
                        <div className="title-deadline">{!_.isEmpty(applyApplication) && applyApplication.title}</div>
                        <div className="fonts-status">
                            <span>
                                {t("general.Status")}
:
                            </span>
                            <div className="color-text">{!_.isEmpty(applyApplication) && t(`rounderTable.${_.findKey(Constants.FUNDING_APPLICATION_STATUS, _.partial(_.isEqual, applyApplication.status))}`)}</div>
                        </div>
                    </WrapperTitle>
                    <Title />
                    <WrapperContent>
                        {_renderCategories()}
                        {
                            Array.isArray(applyApplication.document) && !_.isEmpty(applyApplication.document) && (
                                <WrapperTitle marginTop="1em">
                                    <UploadContainer>
                                        <UploadListItem>
                                            <Title>{t("documentTemplate.Document Template")}</Title>
                                            {applyApplication.document_json && (_renderTemplates())}
                                        </UploadListItem>
                                        <UploadTemplate>
                                            <Title>{t("general.Upload Document")}</Title>
                                            {_renderUploadTemplate()}
                                        </UploadTemplate>
                                    </UploadContainer>
                                </WrapperTitle>
                            )
                        }
                        <WrapperButton>
                            <Button
                                background={ColorName.chathamsBlueDark}
                                title={t("general.Back")}
                                width="8em"
                                margin="0 1.5em 0 0"
                                height="35px"
                                onClick={() => history.push("user-applications-detail")}
                            />
                            <Button
                                title={t("general.Save Draft")}
                                height="35px"
                                width="10em"
                                margin="0 1.5em 0 0"
                                onClick={() => saveDraft()}
                                background={ColorName.chathamsBlueDark}
                            />
                            <Button
                                margin='0px 0px 0px 10px'
                                background={ColorName.chathamsBlueDark}
                                title={t("general.Submit")}
                                width="8em"
                                height="35px"
                                onClick={() => submitQuestionnaire()}
                            />
                        </WrapperButton>
                    </WrapperContent>
                </BodyContainer>
            )}
        />
    );
};

export default UserApplyApplications;
